<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].registry_id.label"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="isImported || !isImported"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              :disabled="isImported || !isImported"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].number.label"
                        vid="number"
                        :label="beForm[rep].number.label"
                        v-model="form[rep].number"
                        placeholder="Inserisci un numero polizza"
                        :rules="getRules('number')"
                        :disabled="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione libera"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                        :disabled="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="mandate_code_id"
                        :name="beForm[rep].mandate_code_id.label"
                        :label="beForm[rep].mandate_code_id.label"
                        :options="mandate_code_options"
                        v-model="form[rep].mandate_code_id"
                        placeholder="Seleziona un codice mandato"
                        :disabled="
                          isImported && form[rep].mandate_code_id != null
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Prodotto Assicurativo"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurer_id"
                        :name="beForm[rep].insurer_id.label"
                        :label="beForm[rep].insurer_id.label"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        @input="onInputInsurer"
                        :rules="getRules('insurer_id')"
                        :disabled="isImported || !isImported"
                      />
                    </div>

                    <div class="col-md-3">
                      <base-select
                        name="Ramo"
                        vid="branch"
                        label="Ramo"
                        :options="risk_branches"
                        v-model="form.risk_branch_id"
                        :multiple="false"
                        :closeOnSelect="false"
                        @input="onInputBranch"
                        :disabled="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurance_risk_id"
                        :name="beForm[rep].insurance_risk_id.label"
                        :label="beForm[rep].insurance_risk_id.label"
                        v-model="form[rep].insurance_risk_id"
                        :options="prdct"
                        :rules="getRules('insurance_risk_id')"
                        :disabled="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                        :disabled="isImportedStatusPolicy"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Date" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        :name="beForm[rep].broker_id.label"
                        vid="broker_id"
                        :label="beForm[rep].broker_id.label"
                        :options="broker_options"
                        v-model="form[rep].broker_id"
                        :rules="getRules('broker_id')"
                        :disabled="isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="payment_splitting"
                        :name="beForm[rep].payment_splitting.label"
                        :label="beForm[rep].payment_splitting.label"
                        v-model="form[rep].payment_splitting"
                        :options="beForm[rep].payment_splitting.options"
                        :rules="getRules('payment_splitting')"
                        @select="onPaymentSplitting"
                        :disabled="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="status_policy"
                        :name="beForm[rep].status_policy.label"
                        :label="beForm[rep].status_policy.label"
                        v-model="form[rep].status_policy"
                        :options="beForm[rep].status_policy.options"
                        :rules="getRules('status_policy')"
                        @input="onInputStatusPolicy"
                        :disabled="isImportedStatusPolicy || disabled"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="issued_at"
                        :name="beForm[rep].issued_at.label"
                        :label="beForm[rep].issued_at.label"
                        v-model="form[rep].issued_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting)
                        "
                        :rules="getRules('issued_at')"
                        :disabled="isImported || !isImported"
                        :readonly="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_at"
                        :name="beForm[rep].effective_at.label"
                        :label="beForm[rep].effective_at.label"
                        v-model="form[rep].effective_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting, true)
                        "
                        :rules="getRules('effective_at')"
                        :disabled="isImported || !isImported"
                        :readonly="isImported || !isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        ref="expired_at"
                        vid="expired_at"
                        :name="beForm[rep].expired_at.label"
                        :label="beForm[rep].expired_at.label"
                        v-model="form[rep].expired_at"
                        :rules="getRules('expired_at')"
                        :disabled="isImported"
                        :readonly="isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="covered_at"
                        :name="beForm[rep].covered_at.label"
                        :label="beForm[rep].covered_at.label"
                        v-model="form[rep].covered_at"
                        :rules="getRules('covered_at')"
                        :disabled="isImported"
                        :readonly="isImported"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="suspended_at"
                        :name="beForm[rep].suspended_at.label"
                        :label="beForm[rep].suspended_at.label"
                        v-model="form[rep].suspended_at"
                        :rules="getRules('suspended_at')"
                        :disabled="
                          isImported ||
                          (!isImported &&
                            ![2].includes(form[rep].status_policy))
                        "
                        :readonly="
                          isImported ||
                          (!isImported &&
                            ![2].includes(form[rep].status_policy))
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="cancelled_at"
                        :name="beForm[rep].cancelled_at.label"
                        :label="beForm[rep].cancelled_at.label"
                        v-model="form[rep].cancelled_at"
                        :rules="getRules('cancelled_at')"
                        :disabled="
                          isImported ||
                          (!isImported &&
                            ![10].includes(form[rep].status_policy))
                        "
                        :readonly="
                          isImported ||
                          (!isImported &&
                            ![10].includes(form[rep].status_policy))
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_NONOT_value"
                        :name="beForm[rep].attribute_NONOT_value.label"
                        :label="beForm[rep].attribute_NONOT_value.label"
                        v-model="form[rep].attribute_NONOT_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_NONOT_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Premio" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].net_premium.label"
                        vid="net_premium"
                        :label="beForm[rep].net_premium.label"
                        v-model="form[rep].net_premium"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :rules="getRules('net_premium')"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].tax_premium.label"
                        vid="tax_premium"
                        :label="beForm[rep].tax_premium.label"
                        v-model="form[rep].tax_premium"
                        :rules="getRules('tax_premium')"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        readonly
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].gross_premium.label"
                        vid="gross_premium"
                        :label="beForm[rep].gross_premium.label"
                        v-model="form[rep].gross_premium"
                        :rules="getRules('gross_premium')"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        readonly
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Collaborazioni"
                header-tag="header"
                class="mt-1"
                v-if="!isImported"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="collaborator_id"
                        :name="beForm[rep].collaborator_id.label"
                        :label="beForm[rep].collaborator_id.label"
                        v-model="form[rep].collaborator_id"
                        :options="beForm[rep].collaborator_id.options"
                        @input="onInputCooperator"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        v-if="form[rep].collaborator_id"
                        vid="tipo_incasso"
                        :name="beForm[rep].collaboration_type.label"
                        label="Tipo Incasso"
                        v-model="form[rep].collaboration_type"
                        :options="[
                          { text: 'Incasso diretto', value: 0 },
                          { text: 'Tramite collaborazione', value: 1 },
                          { text: 'Nessuna ', value: 2 },
                        ]"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Delega - Nostra"
                header-tag="header"
                class="mt-1"
                v-if="form[rep].sharing_type === 0"
              >
                <b-card-text>
                  <!-- row header -->
                  <b-row>
                    <div class="col-md-2"></div>
                    <div class="col-md-2">
                      <!-- Quota Nostra -->
                      <base-currency
                        vid="quota"
                        name="quota"
                        label="Quota Nostra"
                        v-model="form[rep].sharing"
                        :options="{
                          locale: 'it-IT',
                          currencyDisplay: 'percent',
                          precision: 2,
                        }"
                        placeholder="... quota"
                        :readonly="isImported"
                      />
                      <!-- v-model="form.coass.share" -->
                    </div>
                    <!-- <div class="col-md-2">
                      <base-currency
                        vid="net"
                        name="net"
                        label="Netto"
                        :value="form[rep].net_premium"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div>
                    <div class="col-md-2">
                      <base-currency
                        vid="tax"
                        name="tax"
                        label="Tasse"
                        :value="form[rep].tax_premium"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div>
                    <div class="col-md-2">
                      <base-currency
                        vid="gross"
                        name="gross"
                        label="Lordo"
                        :value="form[rep].gross_premium"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div> -->
                  </b-row>
                  <hr />
                  <div v-if="!isImported">
                    <coass-details
                      ref="coassRef"
                      :details="Object.values(coassdtlscfg)"
                      v-model="form"
                      repository="insurer_participant"
                      canDelete
                      :disableAmounts="true"
                      :hideAmounts="true"
                      @select="
                        selectEditCoassDetail(
                          form.coass.coass_details,
                          ...arguments
                        )
                      "
                      @unselect="
                        unselectEditCoassDetail(
                          form.coass.coass_details,
                          ...arguments
                        )
                      "
                      @delete="
                        deleteEditCoassDetail(
                          form.coass.coass_details,
                          ...arguments
                        )
                      "
                    ></coass-details>
                  </div>
                  <!-- tutto readonly -->
                  <div v-else>
                    <coass-details
                      ref="coassRefReadonly"
                      :details="Object.values(coassdtlscfg)"
                      v-model="form"
                      repository="insurer_participant"
                      :canDelete="false"
                      :disableAmounts="true"
                      :hideAmounts="true"
                      :disableCoass="true"
                      :disableShare="true"
                    ></coass-details>
                  </div>
                  <hr />
                  <!-- row footer -->
                  <b-row>
                    <div class="col-md-2"></div>
                    <div class="col-md-2">
                      <!-- Totale Quota Nostra + Coass -->
                      <!-- :value="
                              form.coass.share +
                              coassDetailsEditTotal('share')
                            " -->
                      <base-currency
                        vid="quota"
                        name="quota"
                        label="Totale Quota"
                        :value="
                          parseFloat(form[rep].sharing) +
                          coassDetailsEditTotal('share')
                        "
                        :options="{
                          locale: 'it-IT',
                          currencyDisplay: 'percent',
                          precision: 2,
                        }"
                        :readonly="true"
                      />
                    </div>
                    <!-- <div class="col-md-2">
                      <base-currency
                        vid="net"
                        name="net"
                        label="Totale Netto"
                        :value="
                          parseFloat(form[rep].net_premium) +
                          coassDetailsEditTotal('net')
                        "
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div>
                    <div class="col-md-2">
                      <base-currency
                        vid="tax"
                        name="tax"
                        label="Totale Tasse"
                        :value="
                          parseFloat(form[rep].tax_premium) +
                          coassDetailsEditTotal('tax')
                        "
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div>
                    <div class="col-md-2">
                      <base-currency
                        vid="gross"
                        name="gross"
                        label="Totale Lordo"
                        :value="
                          parseFloat(form[rep].net_premium) +
                          parseFloat(form[rep].tax_premium) +
                          coassDetailsEditTotal('net') +
                          coassDetailsEditTotal('tax')
                        "
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div> -->
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Delega - Altrui"
                header-tag="header"
                class="mt-1"
                v-if="form[rep].sharing_type === 1"
              >
                <!-- #1172
              Compagnia Coass → Delegataria
              Percentuale delega coassicurazione → Quota Nostra delega -->
                <!-- v-if="form[rep].sharing_type === 0 && isInsurerParticipant" -->
                <b-card-text>
                  <b-row>
                    <div class="col-md-4">
                      <base-select
                        vid="insurer_participant"
                        name="insurer_participant"
                        label="Delegataria"
                        v-model="form.insurer_participant"
                        :options="getCoassOptions()"
                      />
                    </div>
                    <!-- :label="beForm[rep].sharing.label" -->
                    <!-- :name="beForm[rep].sharing.label" -->
                    <div class="col-md-4">
                      <base-currency
                        vid="sharing"
                        name="sharing"
                        label="Quota Nostra Delega"
                        v-model="form[rep].sharing"
                        :rules="getRules('sharing')"
                        :options="{
                          locale: 'it-IT',
                          currencyDisplay: 'percent',
                          precision: 2,
                        }"
                        placeholder="Inserisci quota nostra delega"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <custom-inputs
                        v-for="(element, index) in customInputs[rep]"
                        :key="index"
                        :input="element"
                        :customInputs="customInputs[rep]"
                        v-model="form[rep]"
                        :beForm="beForm[rep]"
                        :beRules="beRules[rep]"
                      />
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import CustomInputs from "@/components/form/CustomInputs";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import CoassDetails from "@/components/form/CoassDetails";
import CoassDetailsMixin from "@/mixins/CoassDetailsMixin";
import { calcPolicyDate } from "@/utils/dates";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, CoassDetailsMixin],
  data() {
    return {
      repository: "insurance_policy",
      isLoadingAjax: false,
      isImported: false,
      isImportedStatusPolicy: false,
      disabled: false,
      ajaxOptions: [],
      broker_options: [],
      mandate_code_options: [],
      companies: [],
      risk_branches: [],
      risks: [],
      products: [],
      coassDetails: [],
      status_policy_opt: [
        { value: 0, text: "Non Perfezionata" },
        { value: 1, text: "Perfezionata" },
        { value: 2, text: "Sospesa" },
      ],
      id: this.$route.params.id,
      item: this.$route.params.item,
      registry_data: null,
      status_policy: {
        NON_PERFEZIONATA: 0,
        PERFEZIONATA: 1,
        SOSPESA: 2,
        ANNULLATA: 4,
        STORNATA: 10,
      },
      form: {
        quick_value: "",
        inpt_label: "",
        insurer_participant: null,
        insurance_ancillary: null,
        insurance_policy: {
          gross_premium: null,
          net_premium: null,
          tax_premium: null,
          mandate_code_id: null,
          number: null,
          producer_code: null,
          registry_id: null,
          insurer_id: null,
          insurance_risk_id: null,
          status_policy: null,
          title: null,
          suspended_at: null,
          covered_at: null,
          effective_at: null,
          expired_at: null,
          issued_at: null,
          cancelled_at: null,
          payment_splitting: null,
          collaborator_id: null,
          collaboration_type: null,
        },
        coass: {
          // DYNAMICALLY ADD ELEMENTS
          coass_details: {},
        },
      },
    };
  },
  props: {},
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    CustomInputs,
    BaseDatepicker,
    BaseCurrency,
    QuickSearchRegistryModal,
    CoassDetails,
  },
  methods: {
    toInfoData,
    initDefaultValues() {
      // REVIEW: workaround per disegnare la forma di pagamento vuota
      this.editCoassDetailsCompToggle = true;
    },
    updateCoassPolicy(insurancePolicyId, relation) {
      let promises = [];
      if (this.form[this.rep].sharing_type === 1) {
        // altrui
        let payload = {
          insurer_participant: {},
        };

        payload.insurer_participant[this.form.insurer_participant] = {
          share: 100 - this.form[this.rep].sharing,
        };
        const Repo = RepositoryFactory.get("insurance_policy");
        promises.push(Repo.pivot_update(insurancePolicyId, relation, payload));
      } else if (this.form[this.rep].sharing_type === 0) {
        // nostra
        const Repo = RepositoryFactory.get("insurance_policy");
        let payload_store = {
          insurer_participant: {},
        };
        let payload_update = {
          insurer_participant: {},
        };
        for (const key in this.coassdtlscfg) {
          if (isNaN(parseInt(key))) {
            if (
              this.form.coass.coass_details[key].type &&
              this.form.coass.coass_details[key].share
            ) {
              // payload_store
              payload_store.insurer_participant[
                this.form.coass.coass_details[key].type
              ] = {
                share: this.form.coass.coass_details[key].share || 0,
                net: this.form.coass.coass_details[key].net || 0,
                tax: this.form.coass.coass_details[key].tax || 0,
                gross:
                  (this.form.coass.coass_details[key].net || 0) +
                  (this.form.coass.coass_details[key].tax || 0),
                participant_prov_purchase: 0,
                participant_prov_take: 0,
              };
            }
          } else {
            // payload_update
            payload_update.insurer_participant[
              this.form.coass.coass_details[key].type
            ] = {
              share: this.form.coass.coass_details[key].share || 0,
              net: this.form.coass.coass_details[key].net || 0,
              tax: this.form.coass.coass_details[key].tax || 0,
              gross:
                (this.form.coass.coass_details[key].net || 0) +
                (this.form.coass.coass_details[key].tax || 0),
              participant_prov_purchase: 0,
              participant_prov_take: 0,
            };
          }
        }
        // pivot_store
        if (Object.keys(payload_store.insurer_participant).length) {
          promises.push(
            Repo.pivot_store(insurancePolicyId, relation, payload_store)
          );
        }
        // pivot_update
        if (Object.keys(payload_update.insurer_participant).length) {
          promises.push(
            Repo.pivot_update(insurancePolicyId, relation, payload_update)
          );
        }
      }
      return promises;
    },
    onPaymentSplitting(val, skip = false) {
      // Data decorrenza = Data emissione
      if (!skip) {
        this.form[this.repository].effective_at =
          this.form[this.repository].issued_at;
      }

      // Data scadenza = Data decorrenza + 1 anno
      this.form[this.repository].expired_at = calcPolicyDate(
        this.form[this.repository].effective_at
      ).format("YYYY-MM-DD");
      // Data copertura = Data decorrenza + Frazionamento
      this.form[this.repository].covered_at = calcPolicyDate(
        this.form[this.repository].effective_at,
        val
      ).format("YYYY-MM-DD");
    },
    onInputInsurer(insurer) {
      this.form[this.rep].insurer_id = insurer;
      this.form.risk_branch_id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.form[this.rep].insurance_risk_id = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      // reset mandate_code_id
      this.form[this.rep].mandate_code_id = null;
      // carica (o svuota) la tendina dei mandati
      this.mandate_code_options = [];
      if (insurer) {
        this.mandate_code_options = this.getMandateCodes()(insurer);
      }
    },
    onInputBranch(branches) {
      this.form[this.rep].insurance_risk_id = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        branches,
        this.form.risk_id
      );
    },
    onInputStatusPolicy(value) {
      if (!this.isImported) {
        switch (value) {
          case this.status_policy.SOSPESA:
            this.form[this.rep].cancelled_at = null;
            break;
          case this.status_policy.STORNATA:
            this.form[this.rep].suspended_at = null;
            break;
          default:
            this.form[this.rep].cancelled_at = null;
            this.form[this.rep].suspended_at = null;
        }
      }
    },
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    checkImportedInsurancePolicy() {
      if (this.item.insurer && this.item.insurer.importer_id) {
        this.isImported = true;
        if (
          [
            this.status_policy.PERFEZIONATA,
            this.status_policy.SOSPESA,
            this.status_policy.ANNULLATA,
            this.status_policy.STORNATA,
          ].includes(this.item.status_policy.value)
        ) {
          this.beForm[this.rep].status_policy.options = this.beForm[
            this.rep
          ].status_policy.options.filter((status) =>
            [
              this.status_policy.PERFEZIONATA,
              this.status_policy.SOSPESA,
              this.status_policy.ANNULLATA,
              this.status_policy.STORNATA,
            ].includes(status.value)
          );
        } else {
          this.isImportedStatusPolicy = true;
        }
      }
    },
    checkPolicyStatus() {
      if (
        this.item.status_policy.value === this.status_policy.NON_PERFEZIONATA
      ) {
        this.disabled = true;
      } else {
        this.beForm[this.rep].status_policy.options = this.beForm[
          this.rep
        ].status_policy.options.filter((status) =>
          [
            this.status_policy.PERFEZIONATA,
            this.status_policy.SOSPESA,
            this.status_policy.ANNULLATA,
            this.status_policy.STORNATA,
          ].includes(status.value)
        );
      }
    },
    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onInputCooperator(value) {
      if (!value) {
        this.form[this.rep].collaboration_type = null;
      }
    },
    onSubmit() {
      // checks...
      // delega nostra...
      if (this.form[this.rep].sharing_type == 0) {
        // 1. Nostra Quota + tutte le Quote deve fare 100
        if (
          parseFloat(
            // this.form.coass.share + this.coassDetailsEditTotal("share")
            parseFloat(this.form[this.rep].sharing) +
              this.coassDetailsEditTotal("share")
          ).toFixed(2) !== "100.00"
        ) {
          this.$showSnackbar({
            preset: "error",
            text: "La quota totale deve essere 100%",
          });
          return;
        }
      }
      let promises = [];
      this.isLoading = true;
      // let insurance_ancillary;
      this.update(this.repository, this.id)
        .then((/* response */) => {
          // coass
          if (
            // this.isInsurerParticipant &&
            this.form[this.rep].sharing_type === 1
          ) {
            // delega altrui
            promises.push(
              ...this.updateCoassPolicy(this.id, "insurer_participant")
            );
          } else if (
            // this.isInsurerParticipant &&
            this.form[this.rep].sharing_type === 0
          ) {
            // delega nostra
            // salvo i pivot se non è tutto readonly (cioè !isImported)
            if (!this.isImported) {
              promises.push(
                ...this.updateCoassPolicy(this.id, "insurer_participant")
              );
            }
          }
          Promise.all(promises)
            .then(() => {
              /* //status_policy "perfezionata"
              this.onSuccess(insurance_policy_id); */
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Polizza Modificata`,
              });
              this.shortcut(
                "module.PFOLIO",
                null,
                "#Policies",
                "filterInsurancePolicy"
              );
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      getMandateCodes: "mandate_codes",
    }),
  },
  created() {
    /* // TODO: aggiungere da qualche parte la chiamata per ottenere i dettagli di coass
    // la show della polizza...
    this.initDefaultValues();
    this.coassDetails = this.item.insurer_participant;
    this.changeCoassDetails(this.form.coass.coass_details); */

    this.isLoading = true;
    this.broker_options = this.getSalesmen();
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.companies = this.getInsurers();
      this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
      this.mandate_code_options = this.getMandateCodes()(
        this.form[this.rep].insurer_id
      );
      this.risks = this.getRisks();
      this.form.risk_branch_id = this.getProducts()().find(
        (product) => product.value === this.form[this.rep].insurance_risk_id
      ).risk_branch_id;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      let shows = [];
      // registry show
      const RepoR = RepositoryFactory.get("registry");
      // policy show
      const RepoP = RepositoryFactory.get(this.repository);
      let relationStringP =
        "relations/byInsuranceAncillary/byInsurerParticipant";
      // "relations/byInsurerParticipant";
      shows.push(RepoR.show(this.beForm[this.rep].registry_id.value));
      shows.push(RepoP.show(this.id, relationStringP));

      Promise.all(shows)
        .then((responses) => {
          for (const [index, response] of responses.entries()) {
            // registry
            if (index === 0) {
              const data = response.data.data;
              this.checkImportedInsurancePolicy();
              this.checkPolicyStatus();
              this.registry_data = data;
              this.form[this.rep].registry_id =
                this.beForm[this.rep].registry_id.value;
              this.form.inpt_label = this.toInfoData(
                this.registry_data,
                "registryfullname"
              );
            }
            // policy
            if (index === 1) {
              const data = response.data.data;
              this.coassDetails = data.insurer_participants;
              // titolo (per salvare la pivot )
              this.insurance_ancillary = data.insurance_ancillaries.find(
                (e) => e.insurance_policy_id == this.id
              );
              this.initDefaultValues();
              this.changeCoassDetails(this.form.coass.coass_details);
              // se delega altrui
              if (this.form[this.rep].sharing_type == 1) {
                // è delega altrui, prendo l'unico participant della polizza
                this.form.insurer_participant =
                  data.insurer_participants[0]?.id;
              }
              // // chiamo la show dei titoli? NO!!!
              // const RepoA = RepositoryFactory.get("insurance_ancillary");
              // let relationStringA = "relations/byInsurerParticipant";
              // RepoA.show(this.insurance_ancillary.id, relationStringA)
              //   .then((response) => {
              //     this.coassDetails = response.data.data.insurer_participants;
              //     // titolo (per salvare la pivot )
              //     this.initDefaultValues();
              //     this.changeCoassDetails(this.form.coass.coass_details);
              //   })
              //   .catch((error) => {
              //     let errMsg = this.$getErrorMessage(error);
              //     this.$showSnackbar({
              //       preset: "error",
              //       text: errMsg,
              //     });
              //   });
            }
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      // const Repo = RepositoryFactory.get("registry");
      // Repo.show(this.beForm[this.rep].registry_id.value)
      //   .then((response) => {
      //     const data = response.data.data;
      //     this.checkImportedInsurancePolicy();
      //     this.checkPolicyStatus();
      //     this.registry_data = data;
      //     this.form[this.rep].registry_id =
      //       this.beForm[this.rep].registry_id.value;
      //     this.form.inpt_label = this.toInfoData(
      //       this.registry_data,
      //       "registryfullname"
      //     );
      //   })
      //   .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     this.$showSnackbar({
      //       preset: "error",
      //       text: `${errMsg}`,
      //     });
      //   })
      //   .finally(() => {
      //     this.isLoading = false;
      //   });
    });
  },
  computed: {
    coassDetailsEditTotal() {
      return (field) => {
        return Object.keys(this.form.coass.coass_details)
          .map((key) => {
            return +this.form.coass.coass_details[key][field] || 0;
          })
          .reduce(function (sum, i) {
            return sum + i;
          }, 0);
      };
    },
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    prdct() {
      return this.products;
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].registry_id) {
          this.registry_data = null;
        }
      },
    },
  },
};
</script>
