var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Identificativi",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-button-toolbar",
                                                              [
                                                                _c(
                                                                  "b-button-group",
                                                                  [
                                                                    _c(
                                                                      "base-input",
                                                                      {
                                                                        attrs: {
                                                                          name: _vm
                                                                            .beForm[
                                                                            _vm
                                                                              .rep
                                                                          ]
                                                                            .registry_id
                                                                            .label,
                                                                          label:
                                                                            _vm
                                                                              .beForm[
                                                                              _vm
                                                                                .rep
                                                                            ]
                                                                              .registry_id
                                                                              .label,
                                                                          rules:
                                                                            _vm.getRules(
                                                                              "registry_id"
                                                                            ),
                                                                          readonly:
                                                                            _vm.isImported ||
                                                                            !_vm.isImported,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .inpt_label,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                "inpt_label",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.inpt_label",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Ricerca Veloce",
                                                                          disabled:
                                                                            _vm.isImported ||
                                                                            !_vm.isImported,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.openQuickSearchRegistry,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "search",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "registry_data col-md-3",
                                                      },
                                                      [
                                                        _vm.registry_data
                                                          ? _c("span", {
                                                              staticClass:
                                                                "info",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.toInfoData(
                                                                      _vm.registry_data,
                                                                      "registry"
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "quick-search-registry-modal",
                                                      {
                                                        ref: "quickSearchRegistry",
                                                        on: {
                                                          input:
                                                            _vm.handleRegistryModalInput,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].number.label,
                                                          vid: "number",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .number.label,
                                                          placeholder:
                                                            "Inserisci un numero polizza",
                                                          rules:
                                                            _vm.getRules(
                                                              "number"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].number",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title.label,
                                                          vid: "title",
                                                          label:
                                                            "Descrizione libera",
                                                          placeholder:
                                                            "Inserisci una descrizione",
                                                          rules:
                                                            _vm.getRules(
                                                              "title"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "mandate_code_id",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].mandate_code_id
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .mandate_code_id
                                                              .label,
                                                          options:
                                                            _vm.mandate_code_options,
                                                          placeholder:
                                                            "Seleziona un codice mandato",
                                                          disabled:
                                                            _vm.isImported &&
                                                            _vm.form[_vm.rep]
                                                              .mandate_code_id !=
                                                              null,
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .mandate_code_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "mandate_code_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].mandate_code_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Prodotto Assicurativo",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "insurer_id",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].insurer_id.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .insurer_id.label,
                                                          options:
                                                            _vm.companies,
                                                          rules:
                                                            _vm.getRules(
                                                              "insurer_id"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.onInputInsurer,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .insurer_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "insurer_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].insurer_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-3" },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: "Ramo",
                                                          vid: "branch",
                                                          label: "Ramo",
                                                          options:
                                                            _vm.risk_branches,
                                                          multiple: false,
                                                          closeOnSelect: false,
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.onInputBranch,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .risk_branch_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "risk_branch_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.risk_branch_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "insurance_risk_id",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].insurance_risk_id
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .insurance_risk_id
                                                              .label,
                                                          options: _vm.prdct,
                                                          rules:
                                                            _vm.getRules(
                                                              "insurance_risk_id"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .insurance_risk_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "insurance_risk_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].insurance_risk_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "delay_days",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].delay_days.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .delay_days.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .delay_days
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "delay_days"
                                                            ),
                                                          disabled:
                                                            _vm.isImportedStatusPolicy,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .delay_days,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "delay_days",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].delay_days",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Date",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-3" },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].broker_id.label,
                                                          vid: "broker_id",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .broker_id.label,
                                                          options:
                                                            _vm.broker_options,
                                                          rules:
                                                            _vm.getRules(
                                                              "broker_id"
                                                            ),
                                                          disabled:
                                                            _vm.isImported,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .broker_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "broker_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].broker_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "payment_splitting",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].payment_splitting
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .payment_splitting
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .payment_splitting
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "payment_splitting"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        on: {
                                                          select:
                                                            _vm.onPaymentSplitting,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .payment_splitting,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "payment_splitting",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].payment_splitting",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "status_policy",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].status_policy.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .status_policy
                                                              .label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .status_policy
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "status_policy"
                                                            ),
                                                          disabled:
                                                            _vm.isImportedStatusPolicy ||
                                                            _vm.disabled,
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.onInputStatusPolicy,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .status_policy,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "status_policy",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].status_policy",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "issued_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].issued_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .issued_at.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "issued_at"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                          readonly:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        on: {
                                                          select: function (
                                                            $event
                                                          ) {
                                                            return _vm.onPaymentSplitting(
                                                              _vm.form[_vm.rep]
                                                                .payment_splitting
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .issued_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "issued_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].issued_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "effective_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].effective_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .effective_at
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "effective_at"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                          readonly:
                                                            _vm.isImported ||
                                                            !_vm.isImported,
                                                        },
                                                        on: {
                                                          select: function (
                                                            $event
                                                          ) {
                                                            return _vm.onPaymentSplitting(
                                                              _vm.form[_vm.rep]
                                                                .payment_splitting,
                                                              true
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .effective_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "effective_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].effective_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        ref: "expired_at",
                                                        attrs: {
                                                          vid: "expired_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].expired_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .expired_at.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "expired_at"
                                                            ),
                                                          disabled:
                                                            _vm.isImported,
                                                          readonly:
                                                            _vm.isImported,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .expired_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "expired_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].expired_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "covered_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].covered_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .covered_at.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "covered_at"
                                                            ),
                                                          disabled:
                                                            _vm.isImported,
                                                          readonly:
                                                            _vm.isImported,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .covered_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "covered_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].covered_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "suspended_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].suspended_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .suspended_at
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "suspended_at"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            (!_vm.isImported &&
                                                              ![2].includes(
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].status_policy
                                                              )),
                                                          readonly:
                                                            _vm.isImported ||
                                                            (!_vm.isImported &&
                                                              ![2].includes(
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].status_policy
                                                              )),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .suspended_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "suspended_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].suspended_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "cancelled_at",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].cancelled_at.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .cancelled_at
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "cancelled_at"
                                                            ),
                                                          disabled:
                                                            _vm.isImported ||
                                                            (!_vm.isImported &&
                                                              ![10].includes(
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].status_policy
                                                              )),
                                                          readonly:
                                                            _vm.isImported ||
                                                            (!_vm.isImported &&
                                                              ![10].includes(
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].status_policy
                                                              )),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .cancelled_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "cancelled_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].cancelled_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_NONOT_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ]
                                                            .attribute_NONOT_value
                                                            .label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_NONOT_value
                                                              .label,
                                                          options:
                                                            Object.values(
                                                              _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_NONOT_value
                                                                .options
                                                            ).map((o) => {
                                                              return {
                                                                value: o.value,
                                                                text: o.text,
                                                              }
                                                            }),
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_NONOT_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_NONOT_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_NONOT_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Premio",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].net_premium.label,
                                                          vid: "net_premium",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .net_premium
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                          rules:
                                                            _vm.getRules(
                                                              "net_premium"
                                                            ),
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .net_premium,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "net_premium",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].net_premium",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].tax_premium.label,
                                                          vid: "tax_premium",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .tax_premium
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "tax_premium"
                                                            ),
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .tax_premium,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "tax_premium",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].tax_premium",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].gross_premium.label,
                                                          vid: "gross_premium",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .gross_premium
                                                              .label,
                                                          rules:
                                                            _vm.getRules(
                                                              "gross_premium"
                                                            ),
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                          },
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .gross_premium,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "gross_premium",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].gross_premium",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        !_vm.isImported
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Collaborazioni",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              vid: "collaborator_id",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].collaborator_id
                                                                .label,
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .collaborator_id
                                                                  .label,
                                                              options:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .collaborator_id
                                                                  .options,
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.onInputCooperator,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .collaborator_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "collaborator_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].collaborator_id",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _vm.form[_vm.rep]
                                                            .collaborator_id
                                                            ? _c(
                                                                "base-select",
                                                                {
                                                                  attrs: {
                                                                    vid: "tipo_incasso",
                                                                    name: _vm
                                                                      .beForm[
                                                                      _vm.rep
                                                                    ]
                                                                      .collaboration_type
                                                                      .label,
                                                                    label:
                                                                      "Tipo Incasso",
                                                                    options: [
                                                                      {
                                                                        text: "Incasso diretto",
                                                                        value: 0,
                                                                      },
                                                                      {
                                                                        text: "Tramite collaborazione",
                                                                        value: 1,
                                                                      },
                                                                      {
                                                                        text: "Nessuna ",
                                                                        value: 2,
                                                                      },
                                                                    ],
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form[
                                                                        _vm.rep
                                                                      ]
                                                                        .collaboration_type,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form[
                                                                            _vm
                                                                              .rep
                                                                          ],
                                                                          "collaboration_type",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form[rep].collaboration_type",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.form[_vm.rep].sharing_type === 0
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Delega - Nostra",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c("div", {
                                                        staticClass: "col-md-2",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-2",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              vid: "quota",
                                                              name: "quota",
                                                              label:
                                                                "Quota Nostra",
                                                              options: {
                                                                locale: "it-IT",
                                                                currencyDisplay:
                                                                  "percent",
                                                                precision: 2,
                                                              },
                                                              placeholder:
                                                                "... quota",
                                                              readonly:
                                                                _vm.isImported,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].sharing,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "sharing",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].sharing",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("hr"),
                                                    _vm._v(" "),
                                                    !_vm.isImported
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "coass-details",
                                                              {
                                                                ref: "coassRef",
                                                                attrs: {
                                                                  details:
                                                                    Object.values(
                                                                      _vm.coassdtlscfg
                                                                    ),
                                                                  repository:
                                                                    "insurer_participant",
                                                                  canDelete: "",
                                                                  disableAmounts: true,
                                                                  hideAmounts: true,
                                                                },
                                                                on: {
                                                                  select:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.selectEditCoassDetail(
                                                                        _vm.form
                                                                          .coass
                                                                          .coass_details,
                                                                        ...arguments
                                                                      )
                                                                    },
                                                                  unselect:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.unselectEditCoassDetail(
                                                                        _vm.form
                                                                          .coass
                                                                          .coass_details,
                                                                        ...arguments
                                                                      )
                                                                    },
                                                                  delete:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteEditCoassDetail(
                                                                        _vm.form
                                                                          .coass
                                                                          .coass_details,
                                                                        ...arguments
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.form =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "form",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "coass-details",
                                                              {
                                                                ref: "coassRefReadonly",
                                                                attrs: {
                                                                  details:
                                                                    Object.values(
                                                                      _vm.coassdtlscfg
                                                                    ),
                                                                  repository:
                                                                    "insurer_participant",
                                                                  canDelete: false,
                                                                  disableAmounts: true,
                                                                  hideAmounts: true,
                                                                  disableCoass: true,
                                                                  disableShare: true,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.form =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "form",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                    _vm._v(" "),
                                                    _c("hr"),
                                                    _vm._v(" "),
                                                    _c("b-row", [
                                                      _c("div", {
                                                        staticClass: "col-md-2",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-2",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              vid: "quota",
                                                              name: "quota",
                                                              label:
                                                                "Totale Quota",
                                                              value:
                                                                parseFloat(
                                                                  _vm.form[
                                                                    _vm.rep
                                                                  ].sharing
                                                                ) +
                                                                _vm.coassDetailsEditTotal(
                                                                  "share"
                                                                ),
                                                              options: {
                                                                locale: "it-IT",
                                                                currencyDisplay:
                                                                  "percent",
                                                                precision: 2,
                                                              },
                                                              readonly: true,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.form[_vm.rep].sharing_type === 1
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Delega - Altrui",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-4",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              vid: "insurer_participant",
                                                              name: "insurer_participant",
                                                              label:
                                                                "Delegataria",
                                                              options:
                                                                _vm.getCoassOptions(),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .insurer_participant,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "insurer_participant",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.insurer_participant",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-4",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              vid: "sharing",
                                                              name: "sharing",
                                                              label:
                                                                "Quota Nostra Delega",
                                                              rules:
                                                                _vm.getRules(
                                                                  "sharing"
                                                                ),
                                                              options: {
                                                                locale: "it-IT",
                                                                currencyDisplay:
                                                                  "percent",
                                                                precision: 2,
                                                              },
                                                              placeholder:
                                                                "Inserisci quota nostra delega",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].sharing,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "sharing",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].sharing",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      _vm._l(
                                                        _vm.customInputs[
                                                          _vm.rep
                                                        ],
                                                        function (
                                                          element,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "custom-inputs",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                input: element,
                                                                customInputs:
                                                                  _vm
                                                                    .customInputs[
                                                                    _vm.rep
                                                                  ],
                                                                beForm:
                                                                  _vm.beForm[
                                                                    _vm.rep
                                                                  ],
                                                                beRules:
                                                                  _vm.beRules[
                                                                    _vm.rep
                                                                  ],
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form[
                                                                    _vm.rep
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      _vm.rep,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form[rep]",
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            144935190
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }