var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return ["text", "number"].includes(
    _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`])
  )
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-input", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              type: _vm.getInputType(
                _vm.beForm[`attribute_${_vm.input}_value`]
              ),
              rules: _vm.getRules(_vm.input),
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : ["decimal"].includes(
        _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`])
      )
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-input", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              type: "text",
              rules: _vm.getRules(_vm.input),
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`]) ===
      "textarea"
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-textarea", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              rules: _vm.getRules(_vm.input),
              rows: "4",
              maxrows: "4",
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`]) === "select"
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-select", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              options: _vm.beForm[`attribute_${_vm.input}_value`].options,
              rules: _vm.getRules(_vm.input),
              taggable: false,
              multiple: false,
              closeOnSelect: true,
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`]) ===
      "datepicker"
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-datepicker", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              rules: _vm.getRules(_vm.input),
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`]) ===
      "timepicker"
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-timepicker", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              rules: _vm.getRules(_vm.input),
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : _vm.getInputType(_vm.beForm[`attribute_${_vm.input}_value`]) === "radio"
    ? _c(
        "div",
        {
          staticClass: "form-group col-md-3",
          style: { display: _vm.isHidden(_vm.input) ? "none" : "block" },
        },
        [
          _c("base-select", {
            attrs: {
              name: _vm.beForm[`attribute_${_vm.input}_value`].label,
              vid: `attribute_${_vm.input}_value`,
              label: _vm.beForm[`attribute_${_vm.input}_value`].label,
              options: [
                { text: "SI", value: "Y" },
                { text: "NO", value: "N" },
              ],
              rules: _vm.getRules(_vm.input),
              taggable: false,
              multiple: false,
              closeOnSelect: true,
            },
            model: {
              value: _vm.inputVal[`attribute_${_vm.input}_value`],
              callback: function ($$v) {
                _vm.$set(_vm.inputVal, `attribute_${_vm.input}_value`, $$v)
              },
              expression: "inputVal[`attribute_${input}_value`]",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }