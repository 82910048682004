<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].registry_id.label"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="false"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              :disabled="false"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].number.label"
                        vid="number"
                        :label="beForm[rep].number.label"
                        v-model="form[rep].number"
                        placeholder="Inserisci un numero polizza"
                        :rules="getRules('number')"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione libera"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <!-- <base-input
                        :name="beForm[rep].mandate_code.label"
                        vid="mandate_code"
                        :label="beForm[rep].mandate_code.label"
                        v-model="form[rep].mandate_code"
                        placeholder="Inserisci un codice mandato"
                        :readonly="true"
                        :rules="{ required: true }"
                      /> -->
                      <base-select
                        vid="mandate_code_id"
                        :name="beForm[rep].mandate_code_id.label"
                        :label="beForm[rep].mandate_code_id.label"
                        :options="mandate_code_options"
                        v-model="form[rep].mandate_code_id"
                        placeholder="Seleziona un codice mandato"
                        :disabled="!form[rep].insurer_id"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Prodotto Assicurativo"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurer_id"
                        :name="beForm[rep].insurer_id.label"
                        :label="beForm[rep].insurer_id.label"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        @input="onInputInsurer"
                        :rules="getRules('insurer_id')"
                        :disabled="true"
                      />
                    </div>

                    <div class="col-md-3">
                      <base-select
                        name="Ramo"
                        vid="branch"
                        label="Ramo"
                        :options="risk_branches"
                        v-model="form.risk_branch_id"
                        :multiple="false"
                        :closeOnSelect="false"
                        @input="onInputBranch"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="insurance_risk_id"
                        :name="beForm[rep].insurance_risk_id.label"
                        :label="beForm[rep].insurance_risk_id.label"
                        v-model="form[rep].insurance_risk_id"
                        :options="prdct"
                        :rules="getRules('insurance_risk_id')"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                        :disabled="true"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Date" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        :name="beForm[rep].broker_id.label"
                        vid="broker_id"
                        :label="beForm[rep].broker_id.label"
                        :options="broker_options"
                        v-model="form[rep].broker_id"
                        :rules="getRules('broker_id')"
                        :disabled="false"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="payment_splitting"
                        :name="beForm[rep].payment_splitting.label"
                        :label="beForm[rep].payment_splitting.label"
                        v-model="form[rep].payment_splitting"
                        :options="beForm[rep].payment_splitting.options"
                        :rules="getRules('payment_splitting')"
                        @select="onPaymentSplitting"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="status_policy"
                        :name="beForm[rep].status_policy.label"
                        :label="beForm[rep].status_policy.label"
                        v-model="form[rep].status_policy"
                        :options="beForm[rep].status_policy.options"
                        :rules="getRules('status_policy')"
                        @input="onInputStatusPolicy"
                        :disabled="true"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="issued_at"
                        :name="beForm[rep].issued_at.label"
                        :label="beForm[rep].issued_at.label"
                        v-model="form[rep].issued_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting)
                        "
                        :rules="getRules('issued_at')"
                        :disabled="true"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_at"
                        :name="beForm[rep].effective_at.label"
                        :label="beForm[rep].effective_at.label"
                        v-model="form[rep].effective_at"
                        @select="
                          onPaymentSplitting(form[rep].payment_splitting, true)
                        "
                        :rules="getRules('effective_at')"
                        :disabled="true"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        ref="expired_at"
                        vid="expired_at"
                        :name="beForm[rep].expired_at.label"
                        :label="beForm[rep].expired_at.label"
                        v-model="form[rep].expired_at"
                        :rules="getRules('expired_at')"
                        :disabled="true"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="covered_at"
                        :name="beForm[rep].covered_at.label"
                        :label="beForm[rep].covered_at.label"
                        v-model="form[rep].covered_at"
                        :rules="getRules('covered_at')"
                        :disabled="true"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="suspended_at"
                        :name="beForm[rep].suspended_at.label"
                        :label="beForm[rep].suspended_at.label"
                        v-model="form[rep].suspended_at"
                        :rules="getRules('suspended_at')"
                        :disabled="true"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="cancelled_at"
                        :name="beForm[rep].cancelled_at.label"
                        :label="beForm[rep].cancelled_at.label"
                        v-model="form[rep].cancelled_at"
                        :rules="getRules('cancelled_at')"
                        :disabled="true"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="attribute_NONOT_value"
                        :name="beForm[rep].attribute_NONOT_value.label"
                        :label="beForm[rep].attribute_NONOT_value.label"
                        v-model="form[rep].attribute_NONOT_value"
                        :options="
                          Object.values(
                            beForm[rep].attribute_NONOT_value.options
                          ).map((o) => {
                            return { value: o.value, text: o.text };
                          })
                        "
                        :rules="{ required: true }"
                        :disabled="true"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Premio" header-tag="header" class="mt-1">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].net_premium.label"
                        vid="net_premium"
                        :label="beForm[rep].net_premium.label"
                        v-model="form[rep].net_premium"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :rules="getRules('net_premium')"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].tax_premium.label"
                        vid="tax_premium"
                        :label="beForm[rep].tax_premium.label"
                        v-model="form[rep].tax_premium"
                        :rules="getRules('tax_premium')"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].gross_premium.label"
                        vid="gross_premium"
                        :label="beForm[rep].gross_premium.label"
                        v-model="form[rep].gross_premium"
                        :rules="getRules('gross_premium')"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :readonly="true"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <b-row>
                      <custom-inputs
                        v-for="(element, index) in customInputs[rep]"
                        :key="index"
                        :input="element"
                        v-model="form[rep]"
                        :beForm="beForm[rep]"
                        :beRules="beRules[rep]"
                      />
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import CustomInputs from "@/components/form/CustomInputs";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import { calcPolicyDate } from "@/utils/dates";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import { today } from "@/utils/dates";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin],
  data() {
    return {
      repository: "insurance_policy",
      isLoadingAjax: false,
      isImported: false,
      isImportedStatusPolicy: false,
      disabled: false,
      ajaxOptions: [],
      broker_options: [],
      mandate_code_options: [],
      companies: [],
      risk_branches: [],
      risks: [],
      products: [],
      id: this.$route.params.id,
      // item: this.$route.params.item,
      registry_data: null,
      registry_data_initial: null, // remember initial registry (before any user's change)
      // suspendedAt: true,
      // cancellaedAt: true,
      form: {
        quick_value: "",
        inpt_label: "",
        risk_branch_id: null, // OGGI
        insurance_policy: {
          gross_premium: null,
          net_premium: null,
          tax_premium: null,
          mandate_code_id: null,
          number: null,
          producer_code: null,
          registry_id: null,
          insurer_id: null,
          insurance_risk_id: null,
          status_policy: null,
          title: null,
          suspended_at: null,
          covered_at: null,
          effective_at: null,
          expired_at: null,
          issued_at: null,
          cancelled_at: null,
          payment_splitting: null,
        },
        insurance_ancillary: {
          code: "A",
          title: null,
          effective_at: null,
          delay_days: null,
          insurance_policy_id: null,
          broker_id: null,
          status_ancillary: null,
          net: 0.0,
          gross: 0.0,
          tax: 0.0,
        },
      },
    };
  },
  props: {},
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    CustomInputs,
    BaseDatepicker,
    BaseCurrency,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    onPaymentSplitting(val, skip = false) {
      // Data decorrenza = Data emissione
      if (!skip) {
        this.form[this.repository].effective_at =
          this.form[this.repository].issued_at;
      }

      // Data scadenza = Data decorrenza + 1 anno
      this.form[this.repository].expired_at = calcPolicyDate(
        this.form[this.repository].effective_at
      ).format("YYYY-MM-DD");
      // Data copertura = Data decorrenza + Frazionamento
      this.form[this.repository].covered_at = calcPolicyDate(
        this.form[this.repository].effective_at,
        val
      ).format("YYYY-MM-DD");
    },
    onInputInsurer(insurer) {
      this.form[this.rep].insurer_id = insurer;
      this.form.risk_branch_id = null;
      this.risk_branches = this.getBranches()(insurer);
      this.form[this.rep].insurance_risk_id = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      // reset mandate_code_id
      this.form[this.rep].mandate_code_id = null;
      // carica (o svuota) la tendina dei mandati
      this.mandate_code_options = [];
      if (insurer) {
        this.mandate_code_options = this.getMandateCodes()(insurer);
      }
    },
    onInputBranch(branches) {
      this.form[this.rep].insurance_risk_id = null;
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        branches,
        this.form.risk_id
      );
    },
    onInputStatusPolicy(value) {
      if (!this.isImported) {
        switch (value) {
          //Sospesa
          case 2:
            this.form[this.rep].cancelled_at = null;
            break;
          // //Stornata
          case 10:
            this.form[this.rep].suspended_at = null;
            break;
          default:
            this.form[this.rep].cancelled_at = null;
            this.form[this.rep].suspended_at = null;
        }
      }
    },
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    checkImportedInsurancePolicy() {
      // If insurance policy is imported
      if (this.item.insurer && this.item.insurer.importer_id) {
        this.isImported = true;
        // If Insurance_policy status is 'perfezionata', 'sospesa', 'annullata', 'stornata'.
        if ([1, 2, 4, 10].includes(this.item.status_policy.value)) {
          this.beForm[this.rep].status_policy.options = this.beForm[
            this.rep
          ].status_policy.options.filter((status) =>
            [1, 2, 4, 10].includes(status.value)
          );
        } else {
          this.isImportedStatusPolicy = true;
        }
      }
    },
    checkPolicyStatus() {
      // If status_policy is 'non perfezionata' (# card 631)
      if (this.item.status_policy.value === 0) {
        this.disabled = true;
      } else {
        this.beForm[this.rep].status_policy.options = this.beForm[
          this.rep
        ].status_policy.options.filter((status) =>
          [1, 2, 4, 10].includes(status.value)
        );
      }
    },
    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onSubmit() {
      this.showConfirm({
        yesCallback: () => {
          // save
          this.doTrasfer();
        },
        noCallback: null,
        title: "Conferma operazione",
        message: `ATTENZIONE: se esistono titoli in stato "da incassare" diventeranno del nuovo contraente`,
        yesLabel: "PROCEDI",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    doTrasfer() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          /*
          // aggiungere un titolo
          tipo A
          incassato,
          valore 0,
          descrizione "Voltura da contrante NOME COGNOME VECCHIO a NOME COGNOME NUOVO"
          */
          this.form[
            "insurance_ancillary"
          ].title = `Voltura da contraente ${toInfoData(
            this.registry_data_initial,
            "registryfullname"
          )} a ${toInfoData(this.registry_data, "registryfullname")}`;
          this.form["insurance_ancillary"].effective_at = today();
          // this.form["insurance_ancillary"].delay_days = "";
          this.form["insurance_ancillary"].insurance_policy_id = this.id;
          this.form["insurance_ancillary"].broker_id =
            this.form[this.rep].broker_id;
          this.form["insurance_ancillary"].status_ancillary = 1;
          const Repo = RepositoryFactory.get("insurance_ancillary");
          Repo.store(this.form["insurance_ancillary"])
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Voltura effettuata`,
              });
              this.shortcut(
                "module.PFOLIO",
                null,
                "#Policies",
                "filterInsurancePolicy"
              );
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    addAncillary() {},
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getBranches: "branches",
      getRisks: "risks",
      getProducts: "products",
      getMandateCodes: "mandate_codes",
    }),
  },
  created() {
    this.isLoading = true;
    this.broker_options = this.getSalesmen();
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.companies = this.getInsurers();
      this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
      // carica mandate_code
      this.mandate_code_options = this.getMandateCodes()(
        this.form[this.rep].insurer_id
      );
      this.risks = this.getRisks();
      let found = this.getProducts()().find(
        (product) => product.value === this.form[this.rep].insurance_risk_id
      );
      if (found) {
        this.form.risk_branch_id = found.risk_branch_id;
      }

      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.form.risk_branch_id,
        this.form.risk_id
      );
      const Repo = RepositoryFactory.get("registry");
      Repo.show(this.beForm[this.rep].registry_id.value)
        .then((response) => {
          const data = response.data.data;
          // non mi servono perché è quasi tutto readonly
          // this.checkImportedInsurancePolicy();
          // this.checkPolicyStatus();
          this.registry_data = data;
          this.registry_data_initial = data;
          this.form[this.rep].registry_id =
            this.beForm[this.rep].registry_id.value;
          // this.form.inpt_label =
          //   this.registry_data.status_registry.value === 0
          //     ? this.registry_data.attributables.NAME +
          //       " " +
          //       this.registry_data.attributables.SURN
          //     : this.registry_data.attributables.CNAM;
          this.form.inpt_label = this.toInfoData(
            this.registry_data,
            "registryfullname"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    prdct() {
      return this.products;
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].registry_id) {
          this.registry_data = null;
        }
      },
    },
  },
};
</script>
