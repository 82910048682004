var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group col-md-3" }, [
    (!_vm.getInputType(_vm.element) ||
      ["text"].includes(_vm.getInputType(_vm.element))) &&
    _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-input", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
                type: _vm.getInputType(_vm.element),
                placeholder: "Inserisci un " + _vm.element.label,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : ["decimal"].includes(_vm.getInputType(_vm.element)) &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-input", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
                type: "text",
                placeholder: "Inserisci un " + _vm.element.label,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : ["number"].includes(_vm.getInputType(_vm.element)) &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-input", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
                type: _vm.getInputType(_vm.element),
                placeholder: "Inserisci un " + _vm.element.label,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : (!_vm.getInputType(_vm.element) ||
          ["decimal"].includes(_vm.getInputType(_vm.element))) &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-input", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
                type: "text",
                placeholder: "Inserisci un " + _vm.element.label,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : _vm.getInputType(_vm.element) === "textarea" &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-textarea", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
                rows: "4",
                maxrows: "4",
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : _vm.getInputType(_vm.element) === "radio" &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-select", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
                options: [
                  { text: "SI", value: "Y" },
                  { text: "NO", value: "N" },
                ],
                taggable: false,
                multiple: false,
                closeOnSelect: true,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : _vm.getInputType(_vm.element) === "datepicker" &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-datepicker", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : _vm.getInputType(_vm.element) === "timepicker" &&
        _vm.element.is_searchable === "Y"
      ? _c(
          "div",
          [
            _c("base-timepicker", {
              attrs: {
                name: _vm.customFilterName(_vm.element.key),
                vid: _vm.element.key,
                label: _vm.element.label,
              },
              model: {
                value: _vm.inputVal,
                callback: function ($$v) {
                  _vm.inputVal = $$v
                },
                expression: "inputVal",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }